import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

// import ArrowLink from '@src/components/arrowLink'
import CardSection from '@components/index-components/cardSection';
// import BodyText from '@components/bodyText'
import Contact from '@components/contact';

import Hero from '../components/hero';

import Layout from '@components/layout';

const Sekki = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader;
  const products = data.contentfulPage.pageContent[3].card;
  const extraHeaderContent =
    data?.contentfulPage?.pageHeaderSubtitle?.pageHeaderSubtitle;

  return (
    <Layout>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[{ path: '/', name: 'Hem' }]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
        extraMarkdown={extraHeaderContent}
      />
      <CardSection products={products} titlesOnly fitCards mobileColumns />
      <Contact headerText={contactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default Sekki;

Sekki.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query SekkiQuery {
    contentfulPage(pageUrl: { eq: "/sekki" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCardsSectionComponent {
          card {
            title
            linkText
            linkPath
            description
            icons {
              file {
                url
              }
            }
            additionalLinkPath
            additionalLinkText
          }
        }
      }
    }
  }
`;
